<template>
  <div id="gmap-popin">
    <div id="gmap-popin__header">
      <div>
        <span>
          {{ formatDate(data?.local_time) }} <br/>
          <i id="gmap-popin__header__timezone">
            {{ t(`timezone`) }}: {{ timeZone(data?.position?.lat, data?.position?.long) }}
          </i>
        </span>
      </div>
      <div @click="close()" class="h-4 w-4">
        <XMarkIcon />
      </div>
    </div>
    <div class="bg-lightgray px-4 py-3">
      <span>{{ t(`mapPage.route.unitName`) }}:</span>
      <span class="ml-2 font-bold">{{ data?.unit_name }}</span>
    </div>
    <div class="text-xs p-4 space-y-4" v-if="data?.type!=='bridge'">
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.vehicleSpeed`) }}:</p>
        <p>
          <KmhOrMph :value="data?.data?.road_speed" :valueUnit="deemUnit()" />
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.postedSpeedLimit`) }}:</p>
        <p>
          <p v-if="isKnownUnknownSpeed()"> {{ t(`mapPage.route.unknown`) }} </p>
          <p v-else-if="isRamp()"> {{ t(`mapPage.route.ramp`) }} </p>
          <KmhOrMph :value="data.isOsm ? data.data?.fusion?.speed_limit_decision :
          data?.data?.esmart_algo_speed_limit" :valueUnit="deemUnit()" name="posted_speed_limit" v-else />
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.allowedSpeedLimit`) }}:</p>
        <p>
          <KmhOrMph :value="data.isOsm ? data.data?.fusion?.speed_to_apply_with_profile_kmh :
            data?.data?.esmart_target_kmh && data?.data?.esmart_target_kmh !== 0 ? data?.data?.esmart_target_kmh : data?.data?.applied_speed_limit" :valueUnit= "deemUnit()"/>
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p> {{ t(`mapPage.route.gear`) }}</p>
        <p>
          {{ data?.data?.current_gear > 20 || data?.data?.current_gear < -2 ? 0 : data?.data?.current_gear }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <p>{{ t(`mapPage.route.active`) }}:</p>
        <p>{{ data?.data?.active }}</p>
      </div>
    </div>
    <div class="p-4 pt-2 border-t-1 border-[#ebf5ff]" v-if="['overspeed', 'bridge'].includes(data.type)">
      <router-link :to="data.type === 'bridge' ? linkGeoFence : linkOverspeed" class="flex items-center text-blue font-bold cursor-pointer">
        <span>{{ t(`mapPage.route.geofences.details`) }}</span>
        <ArrowLongRightIcon class="ml-2 h-4" />
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useTranslation } from "../../../composables/lang";
import { formatedDate, timeZone, } from "../../../utils/dates";
import { canadianProvincesAndTerritories } from "../../../constants/jurisdictions";
import { ArrowLongRightIcon } from "@heroicons/vue/24/solid";
import { getUnixTime } from "date-fns";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { el } from "date-fns/locale";

const { t } = useTranslation();

const emit = defineEmits(["close"]);

const props = defineProps({
  data: {
    type: Object,
    requiered: true,
  },
  isKmh: Boolean
});

const data = computed(() => props.data);

const linkOverspeed = computed(() => {
  return {
    name: "AnalyticsOverspeedReport",
    query: {
      unit_uuid: props?.data?.data?.unit_uuid,
      time: getUnixTime(new Date(props?.data?.related_overspeed_time)),
    },
  };
});

const linkGeoFence = computed(() => {
  return {
    name: "GeofencesReport",
    query: {
      unit_uuid: props?.data?.data?.unit_uuid,
      time: getUnixTime(new Date(props.data.time)),
    },
  };
});

const isKnownUnknownSpeed = () => {
  const substringsToCheck = [
    "E-SMART GNSS Status Not Valid",
    "E-SMART Tagged Off FastRoad",
    "E-SMART Map Matching is too slow"
  ];
  return substringsToCheck.some(substring => data?.value?.data?.road_name.includes(substring));
}  

const isRamp = () => {
  return data?.value?.data?.road_name.includes("E-SMART RAMP");
}

const deemUnit = () => {
  // TODO: To be improved later, that is not ideal to map provinces here
  const fusionJurisdiction = data?.value?.data?.fusion?.jurisdiction_name;
  if (canadianProvincesAndTerritories.map(el => el.code).includes(fusionJurisdiction)) {
    return 'kmh';
  }
  return data?.value?.data?.country_code === 'CAN' ? 'kmh' : props.isKmh ? 'kmh' :  'mph';
}

const formatDate = (date) => {
  const localTime = date.split(".")[0];
  return formatedDate(new Date(localTime));
};

const close = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
#gmap-popin {
  width: 256px;
  position: relative;
  overflow: hidden;
  background-color: #fff;

  @apply flex flex-col rounded shadow-2xl;

  &__header {
    @apply bg-black md:bg-darkgray flex items-center justify-between px-4 py-3;

    p {
      @apply text-white font-bold text-xs;
    }

    div {
      font-size: 20px;

      @apply text-white font-bold cursor-pointer;
    }

    &__timezone {
      font-size: 11px;
    }
  }

  &__content {
    @apply flex-1 overflow-y-auto w-full overflow-x-hidden rounded bg-white;
  }
}
</style>